import './ClaimStatusText.scss';

const ClaimStatusText = function ({ claim }) {
    let txt = "";

    switch (claim.status * 1) {
        case 0:
            txt = "Submitted";
            break;
        case 1:
            txt = "Waiting for documents";
            break;
        case 5:
            txt = "Waiting for approval";
            break;
        case 20:
            txt = "Approved";
            break;
        case 25:
            txt = "Decline";
            break;
        case 26:
            txt = "Closed technical";
            break;
        default:

    }

    return (
        <div className={`claim-status-text state${claim.status * 1}`}><span>{txt}</span></div>
    );
};

const ClaimStatusLabel = ({ claim }) => {
    let txt = "";

    switch (claim.status * 1) {
        case 0:
            txt = "Submitted";
            break;
        case 1:
            txt = "Waiting for documents";
            break;
        case 5:
            txt = "Waiting for approval";
            break;
        case 20:
            txt = "Approved";
            if (claim.paidTotal)
                txt += " " + claim.paidTotal;
            break;
        case 25:
            txt = "Decline";
            break;
        case 26:
            txt = "Closed technical";
            break;
        default:
    }

    return (
        <div className={`claim-status-label state${claim.status * 1}`}><img src="/img/claim-icon-finished.svg" alt="" /><span>{txt}</span></div>
    );
};

export { ClaimStatusText, ClaimStatusLabel };