import { useApp } from '../app-context';
import { PoliciesList } from '../components/PoliciesList';
import { BanneredMenu } from '../components/BanneredMenu';
import { ClaimsList } from '../components/ClaimsList';
import { TSPage } from '../components/commons';
import './MainPage.scss';

const MainPage = function ({ name, pageState }) {
    const [state, dispatcher] = useApp();

    /*const handleBack = (e) => {
        Utils.displaySimpleYNQuestion(dispatcher, {
            content: "בטוח שברצונך לצאת מהאזור האישי?",
            onYes: (e) => {
                window.location.href = "https://chayuta.com";
            }
        })
    };*/

    const handleOpenClaim = (e) => {
        dispatcher({ action: "showPage", params: { id: "animalSelector" } });
    };

    return (
        < TSPage name={name} pageState={pageState} className="fading" >
            <div className="ts-limiter">
                <BanneredMenu></BanneredMenu>
                <div className="content">
                    <div className="top">
                        <button className="yellow menu-item" onClick={handleOpenClaim}>Submit a new Claim</button>
                        <a href="tel:+18887194629" className="dial-link tel menu-item"><img src="/img/tel-new.svg" alt="tel" />1.888.719.4629</a>
                        <h3 className="main-title">View your claims</h3>
                    </div>

                        {!state.profile?.openClaims?.length && !state.profile?.closeClaims?.length && !state.profile?.pendingClaims?.length ?
                            <div className="no-claims-banner">
                                <img src="/img/dog-pages-pile.svg" alt="a dog with a pile of pages" />
                                <h3>There are no claims</h3>
                                <div className="exp">If needed, you can open a new claim</div>
                                <button className="yellow" onClick={handleOpenClaim}>Submit a new claim</button>
                            </div>
                            :
                            undefined
                        }

                        {state.profile?.pendingClaims?.length ?
                            <ClaimsList claims={state.profile?.pendingClaims} title="Pending claims" />
                            :
                            undefined
                        }

                        {state.profile?.openClaims?.length ?
                            <ClaimsList claims={state.profile?.openClaims} title="Active claims" claimClasses="active"/>
                            :
                            undefined
                        }

                        {state.profile?.closeClaims?.length ?
                            <ClaimsList claims={state.profile?.closeClaims} title="Closed claims" />
                            :
                            undefined
                        }

                        {state.profile?.pets?.length ?
                            <PoliciesList policies={state.profile?.pets} />
                            :
                            undefined
                        }
                </div>
            </div>
        </TSPage >
    );
}

export { MainPage };