import './MenuPage.scss';

import { useApp } from '../app-context';
import { TSPage } from '../components/commons';
import { useEffect, useState } from 'react';

const MenuPage = function (props) {
    const [state, dispatcher] = useApp();
    const [stateMounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    const handleClose = function (e) {
        e.preventDefault();
        dispatcher({ action: "closePage", params: { id: "menu" } });
    };

    const handleDisconnect = function (e) {
        e.preventDefault();
        dispatcher({ action: "disconnect" });
    };

    return (
        <TSPage name={props.name} className={"fading fast" + (stateMounted ? " mounted" : "")} pageState={props?.pageState} onClick={handleClose}>
            <div className="menu" onClick={e => e.stopPropagation()}>

            </div>
            <div className="options" onClick={e => e.stopPropagation()}>
                <img src="/img/avatar-person.svg" alt="avatar" />
                <div className="name">{(state.profile?.pets[0]?.firstName || "") + " " + (state.profile?.pets[0]?.lastName || "")}</div>
                <div className="sep"></div>
                <button onClick={handleDisconnect}>Disconnect</button>
            </div>
        </TSPage>
    );
}

export { MenuPage }
