import { Utils } from "../Utils";

class DashService {
    static requestLoginCode(tel, email) {
        return Utils.apiCallWithParsing({
            action: 'requestLoginCode',
            tel,
            email
        })
    }

    static login(key, pin) {
        return Utils.apiCallWithParsing({
            action: 'login',
            key,
            pin
        });
    }

    static logout(token) {
        return Utils.apiCallWithParsing({
            action: 'logout',
            token
        });
    }

    static tokenLogin(token) {
        return Utils.apiCallWithParsing({
            action: 'tokenLogin',
            token
        });
    }

    static openClaim(token, policyId, petPlace) {
        return Utils.apiCallWithParsing({
            action: 'openClaim',
            token,
            policyId,
            petPlace
        });
    }

    static customerPortal(policyId, boostPolicyId) {
        return Utils.apiCallWithParsing({
            action: 'customerPortal',
            policyId,
            boostPolicyId
        });
    }
}

export { DashService }