import { useApp } from '../app-context';
import { Utils } from '../Utils';
import './ClaimsList.scss';
import { ClaimStatusText, ClaimStatusLabel } from './ClaimStatusText';
import { ClaimTracker } from './ClaimTracker';
import { TSAccordion } from './commons';

const Claim = function ({ claim, claimClasses }) {
    const [, dispatcher] = useApp();

    const handleContinueClaim = (e) => {
        e.preventDefault();
        dispatcher({ action: 'showPage', params: { id: 'botClaim', props: { botScript: "", botToken: claim.botClaimSessionId } } })
    }

    const getPetName = (claim) => {
        for (let i = 0; i < claim.pet.details.pets.length; i++) {
            if (claim.petId === claim.pet.details.pets[i].petId)
                return claim.pet.details.pets[i].petName;
        }
    }

    let petName = ((claim?.pet?.details?.pets && claim.petId) ?  getPetName(claim) : claim?.pet?.details?.name) || 'undefined'; 

    return (
        <div className={"claim" + (claimClasses ? " " + claimClasses : "")}>
            {/* <div className="avatar"><img src={`/img/avatar-${claim?.pet?.details?.type}.svg`} alt="" /></div> */}
            <div className="content">
                <div className="name">{petName}</div>
                <div className="date">{Utils.isoDateToDateString(claim?.openedAt)}</div>

            </div>
            {(claim.status !== -1) && (claim.status < 20 || claim.status > 26) ?
                <ClaimTracker claim={claim} />
                :
                undefined
            }

            {
                claim.status >= 20 && claim.status <= 26 ?
                    <ClaimStatusLabel claim={claim} />
                    :
                    <ClaimStatusText claim={claim} />
            }

            {claim.status === -1 ?
                <div className="claim-button"><button className="orange menu-item" onClick={handleContinueClaim}>Proceed with the claim</button> </div>
                :
                undefined
            }
        </div>
    )
};

const ClaimsList = function ({ claims, title, claimClasses }) {
    const [state] = useApp();
    return (
        <div className="claims-list">
            <TSAccordion title={title} forceToggle={true} uiParams={state.uiParams}>
                <div className="list">
                    {claims.map(v => <Claim claim={v} key={"claim" + v.id} claimClasses={claimClasses}/>)}
                </div>
            </TSAccordion>
        </div>
    );
};

export { ClaimsList };