import { useApp } from '../app-context';
import { BanneredMenu } from '../components/BanneredMenu';
import { ClaimsList } from '../components/ClaimsList';
import { TSPage, TSAccordion } from '../components/commons';
import './PolicyPage.scss';
import { Utils } from '../Utils';
import { DashService } from '../services/DashService';

const PolicyPage = function ({ name, pageState, policy }) {
    const [state, dispatcher] = useApp();

    let pendingClaims = [];
    let openClaims = [];
    let closedClaims = [];

        (state.profile?.pendingClaims || []).forEach(v => {
            if (policy?.id === v.pet.id)
                pendingClaims.push(v);
        });
        (state.profile?.openClaims || []).forEach(v => {
            if (policy?.id === v.pet.id)
                openClaims.push(v);
        });
        (state.profile?.closeClaims || []).forEach(v => {
            if (policy?.id === v.pet.id)
                closedClaims.push(v);
        });

    const handleOpenClaim = async (e) => {
        if (policy.details.pets?.length > 1) {
            dispatcher({ action: "showPage", params: { id: "animalSelector", props: {policyId: policy.id} } });
        } else{
            let data = await DashService.openClaim(state._token, policy?.id);
            console.log(data);
            dispatcher({ action: 'showPage', params: { id: 'botClaim', props: { botScript: data.claimScript, botToken: data.claimSession } } })
        }
    };

    const handleChangePayment = (e) => {
        DashService.customerPortal(policy?.id, policy?.details?.boostPolicyId)
            .then((data) => {
                if (!data.url)
                    throw Error("We did not found the policy");
                else {
                    //window.open(data.url, "_blank");
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.target = "_blank";
                    a.href = data.url;
                    a.click();
                    document.body.removeChild(a);
                }
            })
            .catch(ex => {
                console.error(ex);
            });
    };

    let startDate = Utils.formatLocalDate(Utils.dateStringToObj(policy?.details?.startDate));
    let endDate = Utils.formatLocalDate(Utils.dateStringToObj(policy?.details?.endDate));
    let waitingPeriod = Utils.formatLocalDate(Utils.addDays(Utils.dateStringToObj(policy?.details?.startDate), policy?.details?.waitingPeriod));



    return (
        < TSPage name={name} pageState={pageState} className="fading" >
            <div className="ts-limiter">
                <BanneredMenu policy={policy}></BanneredMenu>
                <div className="mainContent">
                    <div className="top">
                        <button className="yellow menu-item" onClick={handleOpenClaim}>Submit a new Claim</button>
                        <a href="tel:+18887194629" className="dial-link tel menu-item"><img src="/img/tel-dark.svg" alt="tel" />1.888.719.4629</a>
                        <h3 className="main-title">{policy.details.name + "'s policy"}</h3>
                    </div>
                    <div className="card white">
                        <TSAccordion title={"Policy claims"} uiParams={state.uiParams}>
                            <div className="">
                                {!openClaims.length && !closedClaims.length && !pendingClaims.length ?
                                    <div className="exp">There are no Claims</div>
                                    :
                                    undefined
                                }

                                {pendingClaims.length ?
                                    <ClaimsList claims={pendingClaims} title="Pending claims" />
                                    :
                                    undefined
                                }

                                {openClaims.length ?
                                    <ClaimsList claims={openClaims} title="Active claims" claimClasses="active"/>
                                    :
                                    undefined
                                }

                                {closedClaims.length ?
                                    <ClaimsList claims={closedClaims} title="Closed claims" />
                                    :
                                    undefined
                                }
                            </div>
                        </TSAccordion>
                    </div>
                    <div className="card white coverage">
                        <TSAccordion title="Policy coverage" forceToggle={true} uiParams={state.uiParams}>
                            <div className="payment-button"><button className="blue menu-item" onClick={handleChangePayment}>Change payment</button> </div>
                            <table className="detailsTable">
                                <tbody>
                                    <tr>
                                        <td>Product</td>
                                        <td>
                                            {policy?.details?.product || ""}<br />
                                            {policy?.details?.addons?.indexOf("DENTAL") >= 0 ? [<div className="addon">Dental Pack</div>, <br />] : undefined}
                                            {policy?.details?.addons?.indexOf("PREMIUM") >= 0 ? [<div className="addon">Premium Pack</div>, <br />] : undefined}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Start date</td>
                                        <td>{startDate}</td>
                                    </tr>
                                    <tr>
                                        <td>End date</td>
                                        <td>{endDate}</td>
                                    </tr>
                                    <tr>
                                        <td>Coverage starts</td>
                                        <td>{waitingPeriod}</td>
                                    </tr>
                                    <tr>
                                        <td>Policy number</td>
                                        <td>{policy?.details?.policyNumber || ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Annual limit</td>
                                        <td>{policy?.details?.annualLimit || ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Monthly cost</td>
                                        <td>{policy?.details?.monthlyCost || ""}</td>
                                    </tr>
                                    <tr>
                                        <td>First Installment</td>
                                        <td>{policy?.details?.firstInstallment || ""}</td>
                                    </tr>
                                    <tr/>
                                    <tr/>
                                    </tbody>
                            </table>
                                    {policy?.details?.pets?.map((v, k) => {
                                        return (
                                            <table className="detailsTable">
                                                <tbody>
                                                    <tr><td className='petName'>{v.petName || ""}</td><td></td></tr>
                                                    <tr><td>Age</td><td>{v.petAge || ""}</td></tr>
                                                    <tr><td>Breed</td><td>{v.petBreed || ""}</td></tr>
                                                    <tr><td>Deductible</td><td>{v.deductible || ""}</td></tr>
                                                    <tr><td>Copayment</td><td>{v.copayment || ""}</td></tr>
                                                    {/* <tr><td>Monthly cost</td><td>{v.monthlyCost || ""}</td></tr> */}
                                                </tbody>
                                            </table>
                                        );
                                    })}
                        </TSAccordion>
                    </div>
                </div>
            </div>
        </TSPage >
    );
}

export { PolicyPage };