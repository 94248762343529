import { useApp } from '../app-context';
import './PoliciesList.scss';
import { TSAccordion } from './commons';

const Policy = function ({ policy }) {
    const [, dispatcher] = useApp();

    const handleClick = (e) => {
        e.preventDefault();
        dispatcher({ action: "closePage", params: { id: "main" } });
        dispatcher({ action: "showPage", params: { id: "policy", props: { policy: policy } } });
    };

    const getPetNames = (pets) => {
        pets = pets || [];
        let names = pets[0].petName;
        for (let i = 1; i < pets.length; i++) {
            names = names + ", " + pets[i].petName;
        }
        return names;
    }

    if(policy.details.pets?.length > 1) {
        let names = getPetNames(policy.details.pets);
        return (
            <div className="animal" onClick={handleClick}>
                <div className="avatar">
                    <img src={`/img/avatar-${policy.details.type}-new.svg`} alt="" />
                </div>
                <div className="info">
                    <div className="multipet">{policy.details.pets?.length > 1? policy.details.pets.length + " pets policy" : ""}</div>
                    <div className="names">{names}</div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="animal" onClick={handleClick}>
                <div className="avatar">
                    <img src={`/img/avatar-${policy.details.type}-new.svg`} alt="" />
                </div>
                <div className="info">
                    <div className="name">{policy.details.name}</div>
                    <div className="details">
                        <span>{policy.details.age || ""}</span>
                        <span>{policy.details.breed || "Unknown breed"}</span>
                    </div>
                    <div className="multipet">{policy.details.pets?.length > 1? policy.details.pets.length + " pets policy" : ""}</div>
                </div>
            </div>
        )
    }
}

const PoliciesList = function ({ policies }) {
    const [state] = useApp();

    return (
        <div className="animals-list">
            <TSAccordion title='My Policies' forceToggle={true} uiParams={state.uiParams}>
                <div className="list">
                    {policies.map((v, k) => <Policy policy={v} key={"ani" + k} />)}
                </div>
            </TSAccordion>
        </div>
    );
};

export { PoliciesList };