class Utils {
    static apiCall(data) {
        return fetch("/DashAPI", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }

    static apiCallWithParsing(data) {
        return Utils.apiCall(data)
            .then(res => {
                return res.json()
            })
            .then(data => {
                if (!data || data.status !== 0) {
                    let error = new Error(data?.errorData?.errorDescription ?? data?.errorText ?? "We are sorry, the service is not available right now. Please come back later.");
                    error._internal = true;
                    throw error;
                }
                return data;
            })
            .catch(ex => {
                if (ex?._internal)
                    throw ex;
                else
                    throw Error("We are sorry, the service is not available right now. Please come back later.")
            });
    }

    static displaySimpleMsg(dispatcher, content, onOk) {
        const handleOk = (e) => {
            dispatcher({ action: "closeOverlay", params: "msg" });
            if (onOk)
                onOk();
        };
        dispatcher({
            action: "openOverlay", params: {
                overlay: "msg", params: {
                    children: [
                        <div key="txt">{content}</div>,
                        <div key="btns" className="dialog-buttons">
                            <button key="btn1" onClick={handleOk}>OK</button>
                        </div>
                    ]
                }
            }
        });
    }

    static displaySimpleYNQuestion(dispatcher, { content, onYes, onNo, yesText, noText }) {
        const handleBtn = (action, e) => {
            dispatcher({ action: "closePage", params: { id: "msg" } });
            if (action)
                action();
        };
        dispatcher({
            action: "showPage", params: {
                id: "msg",
                props: {
                    children: [
                        <div key="txt">{content}</div>,
                        <div key="btns" className="dialog-buttons">
                            <button key="btnNo" onClick={handleBtn.bind(this, onNo)}>{noText || "No"}</button>
                            <button key="btnYes" onClick={handleBtn.bind(this, onYes)}>{yesText || "Yes"}</button>
                        </div>
                    ]
                }
            }
        });
    }

    static ga_event(eventName, eventData) {
        try {
            if (window.gtag)
                window.gtag('event', eventName, eventData || {});
        } catch (ex) {
            console.error(ex);
        }
    }

    static isoDateToDateString(isoDate) {
        if (!isoDate) return "";
        try {
            let date = new Date(Date.UTC(isoDate.substring(0, 4), isoDate.substring(5, 7) - 1, isoDate.substring(8, 10), isoDate.substring(11, 13), isoDate.substring(14, 16), isoDate.substring(17, 19)));
            return ((date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "/" +
                    (date.getDate() < 10 ? "0" : "") + date.getDate() + "/" +
                (date.getYear() - 100));
        } catch (ex) {
            return "";
        }
    }

    static formatLocalDate(date) {
        if (!date) return "";

        try {
            date = new Date(date.getTime());
            return date.toLocaleDateString('en-us', { month: 'long', year: 'numeric', day: 'numeric' });

        } catch (ignored) {
            try {
                return date.toLocaleDateString();
            } catch (ignored) {
                return "";
            }
        }
    };

    static dateStringToObj(dateString, def) {
        let res = def;

        try {
            if (dateString.length == 8)
                return new Date(
                    dateString.substring(0, 4) * 1,
                    dateString.substring(4, 6) * 1 - 1,
                    dateString.substring(6, 8) * 1
                );
            else
                throw Error("invalid date: " + dateString);
        } catch (ignored) { }

        return res;
    }

    static addDays(date, days) {
        if (!date) return date;

        date.setDate(date.getDate() + days);
        return date;
    }
}

export { Utils }