import React, { useCallback, useEffect } from 'react';

import { useApp } from '../app-context';
import { DashService } from '../services/DashService';
import './PageManager.scss';

const PageManager = function () {
    const [state, dispatcher] = useApp();

    const stableDispatcher = useCallback(dispatcher, []);

    useEffect(() => {
        let token;
        try {
            token = window.localStorage.getItem("dashTkn");
        } catch (ignore) { }
        if (token) {
            DashService.tokenLogin(token)
                .then(data => {
                    if (!data.profile)
                        throw Error("We did not find the requested policy.");

                    stableDispatcher({ action: "loggedIn", params: { token: token } });
                    stableDispatcher({ action: "gotProfile", params: { profile: data.profile, policies: data.policies } });
                })
                .catch((ex) => {
                    stableDispatcher({ action: "showPage", params: { id: "login" } })
                })
        } else
            stableDispatcher({ action: "showPage", params: { id: "login" } })
    }, [stableDispatcher]);

    let pages = state._pages.map(v => {
        const props = v.props || {};
        return React.createElement(v.component, { ...props, pageState: v.state, key: v.id + (v.distinct ? v.distinct : '') });
    })

    return (

        <div className={"page-manager" + (!state.loggingIn ? " on" : "")}>
            {pages}
        </div>
    );
}

export { PageManager }