import React, { useState, useEffect, useRef } from 'react';

import './commons.scss';

const TSPage = function ({ id, name, className, pageState, children, onClick }) {
    let classes = ["page"];
    if (className)
        classes.push(className);
    switch (pageState) {
        case "add":
            classes.push("mounting");
            break;
        case "active":
            classes.push("mounted");
            break;
        case "remove":
            classes.push("unmounting");
            break;
        default:
    }
    if (name)
        classes.push(name + "Page");

    return (
        <div id={id} className={classes.join(" ")} onClick={onClick}>
            {children}
        </div>);
};


const TSInput = function ({ id, doOnSubmit, type, initialValue, value, onChange, style, className, placeholder, inputMode, autoComplete }) {
    const [state, setState] = useState({ value: initialValue || value || "" });

    useEffect(() => {
        //if (value !== undefined && value !== state.value)
        setState({ value: value })
        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        if (onChange)
            onChange(
                { target: { value: state.value } }
            );
    }, []);

    var newProps = { id, value: state.value || "", style, type, className, placeholder, inputMode, autoComplete };
    newProps.onChange = (e) => {
        if (onChange) {
            if (onChange(e))
                setState({ value: e.target.value });
        }
        else
            setState({ value: e.target.value });
    };

    if (doOnSubmit) {
        return <form onSubmit={doOnSubmit}>{React.createElement("input", newProps)}</form>
    } else {
        return React.createElement("input", newProps);
    }
}

const TSToggle = function ({ style, value, onChange, alwaysOn }) {
    const [stateValue, setStateValue] = useState(!!value);

    const handleClick = (e) => {
        setStateValue(!stateValue);
        if (onChange)
            onChange(!stateValue);
    };

    return (
        <div className={"ts-toggle" + (stateValue ? " on" : "")} style={{ fontSize: style?.height || "1.2em" }} onClick={handleClick}>
            <div className="track">
                <div className="marker"></div>
            </div>
        </div>
    );
}

const TSLineSelector = function (props) {
    const [stateIdx, setStateIdx] = useState(0);

    const handleClick = (idx, value) => {
        setStateIdx(idx);
        if (props.onChange)
            props.onChange(idx, value);
    };

    let options = [];
    (props.options || []).forEach((v, k) => {
        options.push(
            <div key={"o" + k} className={"part" + (stateIdx === k ? " selected" : "")} data-content={v.d || v.v || ""} style={{ width: (100 / (props.options.length || 1)) + "%" }} onClick={handleClick.bind(this, k, v.v)}>
                <div className="marker" />
            </div>
        );
    });

    let mpos = props.options.length <= 1 ? "50%" : (100 / (props.options.length - 1)) * stateIdx + "%"

    return (
        <div className="ts-line-selector" style={{ fontSize: props.style?.height || "1.2em" }} >
            <div className="bg">
                {options}
            </div>
            <div className="slider">
                <div className="marker on" style={{ right: mpos }} />
            </div>
        </div>
    );
}

const TSAccordion = React.forwardRef(function ({ titleComponent, title, children, forceToggle, onToggle, uiParams }, ref) {
    const content = useRef(null);

    const [stateOpen, setStateOpen] = useState(!!forceToggle);
    const [stateHeight, setStateHeight] = useState(!!forceToggle ? `${content.current?.scrollHeight}px` : "0px");

    useEffect(() => {
        if (forceToggle !== undefined) {
            setStateOpen(!!forceToggle);
            setStateHeight(
                !forceToggle ? "0px" : `${content.current.scrollHeight}px`
            );
        }
    }, [forceToggle]);

    useEffect(() => {
        resetHeight()
    }, [uiParams?.windowSize, children]);

    const handleToggle = function (onoff) {
        setStateOpen(onoff === undefined ? !stateOpen : onoff);
        setStateHeight(stateOpen ? "0px" : `${content.current?.scrollHeight || 0}px`);

        if (onToggle) {
            setTimeout(() => {
                onToggle(!stateOpen, content.current);
            }, 330);
        }
    };

    const handleChildChanged = function () {
        resetHeight();
    }

    const resetHeight = function () {
        setStateHeight(!stateOpen ? "0px" : `${content.current?.scrollHeight || 0}px`);
    }

    let tc = titleComponent || "h1";
    let newChildren = children;
    if (children && Array.isArray(children)) {
        newChildren = [...children].map(v => {
            if (v?.type === TSAccordion)
                return { ...v, props: { ...v.props, onToggle: handleChildChanged } };
            else
                return v;
        });
    }

    return (
        <div ref={ref} className={"ts-accordion" + (stateOpen ? " open" : "")}>
            {
                React.createElement(tc, { className: "header", onClick: handleToggle.bind(this, undefined) },
                    [
                        <span key="title">{title}</span>,
                        <button key="button"><img src="img/chevron-down.svg" alt="open/close" /></button>
                    ]
                )
            }
            <div className="content" style={{ maxHeight: `${stateHeight}` }} ref={content}>
                {newChildren}
            </div>
        </div>
    );
});

const TSOptionsSelector = function ({ highlight, options, selectOptions, onSelected }) {
    let firstChoice = [];
    let secondChoice = [];
    (options || []).forEach((v, k) => {
        let text = (v.text ?? v) || "";
        if (!text || !text.indexOf)
            return true;

        let pos = text.indexOf(highlight);
        if (v.first)
            firstChoice.push(
                <div key={"s" + k} onClick={onSelected?.bind(this, v)}>{text}</div>
            );
        else if (pos === 0)
            firstChoice.push(
                <div key={"s" + k} onClick={onSelected?.bind(this, v)}><span>{text.substring(0, pos)}</span><b>{highlight}</b><span>{text.substring(pos + highlight.length)}</span></div>
            );
        else if (pos > 0)
            secondChoice.push(
                <div key={"s" + k} onClick={onSelected?.bind(this, v)}><span>{text.substring(0, pos)}</span><b>{highlight}</b><span>{text.substring(pos + highlight.length)}</span></div>
            );
    });

    /*if (selectOptions?.addBefore) {
        firstChoice = selectOptions.addBefore.map((v, k) => {
            return <div key={"c" + k} onClick={onSelected?.bind(this, v)}>{v.text ?? v}</div>
        }).concat(firstChoice);
    }*/

    return (
        <div className="ts-options-selector">
            {firstChoice}
            {secondChoice}
        </div>
    );
}

const TSDateInput = ({ id, doOnSubmit, onChange, className }) => {
    const [stateDate, setStateData] = useState({ pre: "", post: "mm/dd/yyyy" });
    const [stateInp, setStateInp] = useState("");

    const handleInputChanged = (e) => {
        let value = e.target.value || "";
        let clean = value.split("").filter(v => v >= '0' && v <= '9').join("");

        let res = "";
        let step = { stage: "m", val: -1 };
        clean.split("").forEach((v) => {
            switch (step.stage) {
                case "d":
                    if (step.val < 0) {
                        step.val = v * 1;
                        res += v;
                    } else {
                        if (step.val * 10 + v * 1 <= 31 && step.val * 10 + v * 1 >= 1) {
                            step.val = step.val * 10 + v * 1;
                            res += v;
                            step = { stage: "y", val: -1 }
                        }
                        else
                            return false;
                    }
                    break;
                case "m":
                    if (step.val < 0) {
                        step.val = v * 1;
                        res += v;
                    } else {
                        if (step.val * 10 + v * 1 <= 12 && step.val * 10 + v * 1 >= 1) {
                            step.val = step.val * 10 + v * 1;
                            res += v;
                            step = { stage: "d", val: -1 }
                        }
                        else
                            return false;
                    }
                    break;
                case "y":
                    if (step.val < 0) {
                        if (v * 1 < 3 && v * 1 > 0) {
                            step.val = v * 1;
                            res += v;
                        } else
                            return false;
                    } else {
                        res += v;
                        if (res.length >= 8)
                            step = { stage: "f", val: -1 }
                    }
                    break;
                default:
                    return false;
            }
        });

        if (value && (value.charAt(value.length - 1) === '.' || value.charAt(value.length - 1) === '/')) {
            if (step.stage === 'm' && res.length === 1 && res * 1 > 0)
                res = '0' + res;
            else if (step.stage === 'd' && res.length === 3 && res.substring(2, 3) * 1 > 0)
                res = res.substring(0, 2) + '0' + res.substring(2, 3);
        }

        let pre = "";
        let post = "";
        if (res.length < 2) {
            pre = res;
            post = (res.length < 1 ? "m" : "") + "m/dd/yyyy";
        } else if (res.length < 4) {
            pre = res.substring(0, 2) + "/" + res.substring(2);
            post = (res.length < 3 ? "d" : "") + "d/yyyy";
        } else if (res.length < 8) {
            pre = res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4);
            for (let i = 0; i < 8 - res.length; i++)
                post += "y";
        } else {
            pre = res.substring(0, 2) + "/" + res.substring(2, 4) + "/" + res.substring(4);
        }

        setStateData({ pre, post });
        setStateInp(res || "");

        if (onChange)
            onChange(e);

        //update pre/post/inp
    };

    return (

        <div className="ts-date-input">
            <form onSubmit={doOnSubmit}>
                <TSInput id={id} value={stateInp} type="text" onChange={handleInputChanged} className={className} inputMode="numeric" autoComplete="off" />
                <div className="inputOverlay" style={{ direction: "ltr" }}><span className="pre">{stateDate?.pre}</span><span className="marker"></span><span className="post">{stateDate?.post}</span></div>
            </form>
        </div>
    );
};

const TSMonthInput = ({ id, onChange, className }) => {
    const [stateDate, setStateData] = useState({ pre: "", post: "mm/yy" });
    const [stateInp, setStateInp] = useState("");

    const handleInputChanged = (e) => {
        let value = e.target.value || "";
        let clean = value.split("").filter(v => v >= '0' && v <= '9').join("");

        let res = "";
        let step = { stage: "m", val: -1 };
        clean.split("").forEach((v) => {
            switch (step.stage) {
                case "m":
                    if (step.val < 0) {
                        step.val = v * 1;
                        res += v;
                    } else {
                        if (step.val * 10 + v * 1 <= 12 && step.val * 10 + v * 1 >= 1) {
                            step.val = step.val * 10 + v * 1;
                            res += v;
                            step = { stage: "y", val: -1 }
                        }
                        else
                            return false;
                    }
                    break;
                case "y":
                    if (step.val < 0) {
                        if (v * 1 < 4 && v * 1 > 1) {
                            step.val = v * 1;
                            res += v;
                        } else
                            return false;
                    } else {
                        res += v;
                        if (res.length >= 4)
                            step = { stage: "f", val: -1 }
                    }
                    break;
                default:
                    return false;
            }
        });

        if (value && (value.charAt(value.length - 1) === '.' || value.charAt(value.length - 1) === '/')) {
            if (step.stage === 'm' && res.length === 1 && res.substring(0, 1) * 1 > 0)
                res = '0' + res.substring(0, 1);
        }

        res = res.substring(0, 4);

        let pre = "";
        let post = "";
        if (res.length < 2) {
            pre = res;
            post = (res.length < 1 ? "m" : "") + "m/yy";
        } else if (res.length < 4) {
            pre = res.substring(0, 2) + "/" + res.substring(2);
            post = (res.length < 3 ? "y" : "") + "y";
        } else {
            pre = res.substring(0, 2) + "/" + res.substring(2, 4);
        }

        setStateData({ pre, post });
        setStateInp(res || "");

        if (onChange)
            onChange({ target: { value: res } });

        //update pre/post/inp
    };

    return (

        <div className="ts-date-input">
            <TSInput id={id} value={stateInp} type="text" inputMode="numeric" onChange={handleInputChanged} className={className} inputmode="numeric" autoComplete="off" />
            <div className="inputOverlay" style={{ direction: "ltr" }}><span className="pre">{stateDate?.pre}</span><span className="marker"></span><span className="post">{stateDate?.post}</span></div>
        </div>
    );
};

export { TSPage, TSInput, TSToggle, TSLineSelector, TSAccordion, TSOptionsSelector, TSDateInput, TSMonthInput }