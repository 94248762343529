import './AnimalSelectorPage.scss';

import { useState } from 'react';
import { useApp } from '../app-context';
import { TSPage } from '../components/commons';
import { DashService } from '../services/DashService';

const Header = ({ onBack }) => {
    return (
        <div className="popup-header">
            <button className="back"><img src="/img/arrow-right.svg" alt="back" onClick={onBack} /></button>
            <h3>Choose Pet</h3>
            <button className="more"><img src="/img/more-white.svg" alt="menu" /></button>
            <button className="closer backBtn" onClick={onBack}>
                <img src="/img/close.svg" alt="close" />
                Close
            </button>
        </div>
    );
};

const AnimalItem = ({ pet, onSelected, selected }) => {
    let petName = pet.petPlace ? pet.details.pets[pet.petPlace].petName : pet.details.name;
    let petType = pet.petPlace ? pet.details.pets[pet.petPlace].petType : pet.details.type;

    return (
        <button className={`pet-item ${selected ? "selected" : ""}`} onClick={onSelected?.bind(this, pet)}>
            <img src={`/img/avatar-${petType}.svg`} alt="" />
            {petName}
        </button>
    );
};

const AnimalSelectorPage = (props) => {
    const [state, dispatcher] = useApp();
    const [stateSelectedPet, setSelectedPet] = useState(undefined);
    const [stateBusy, setBusy] = useState(false);

    const handleClose = (e) => {
        e.preventDefault();
        dispatcher({ action: "closePage", params: { id: "animalSelector" } });
    };

    const handleSelectedPet = (pet, e) => {
        setSelectedPet(pet);
    };

    const handleSubmit = async (e) => {
        if (!stateSelectedPet || stateBusy) return;

        try {
            setBusy(true);
            let data = await DashService.openClaim(state._token, stateSelectedPet.id, stateSelectedPet.petPlace);
            console.log(data);
            dispatcher({ action: 'showPage', params: { id: 'botClaim', props: { botScript: data.claimScript, botToken: data.claimSession } } })

            dispatcher({ action: 'closePage', params: { id: "animalSelector" } });
        } catch (ex) {
            alert(ex ? JSON.stringify(ex) : "error opening claim");
        } finally {
            setBusy(false);
        }
    };

    let pets = state.profile?.pets || [];
    let newPets = [];
    for (let i = 0; i < pets.length; i++) {
        if (props.policyId) {
            if (pets[i].id === props.policyId) {
                for (let ii = 0; ii < pets[i].details.pets.length; ii++) {
                    let copy = {...pets[i]};
                    copy.petPlace = ii;
                    newPets.push(copy);
                }
            }
        } else {
            if (pets[i].details?.pets && pets[i].details.pets.length > 1) {
                for (let ii = 0; ii < pets[i].details.pets.length; ii++) {
                    let copy = {...pets[i]};
                    copy.petPlace = ii;
                    newPets.push(copy);
                }
                pets[i].petPlace = i;
            } else {
                newPets.push(pets[i]);
            }
        }
    }

    return (
        <TSPage name={props.name} className="rollup fast" pageState={props?.pageState}>
            <div className="height-limiter">
                <div className="container">
                    <Header onBack={handleClose} />
                    <h2 className="fixed">Who are you claiming for?</h2>
                    <div className="content">
                        {newPets.map((v, k) => <AnimalItem key={`pet${k}`} pet={v} selected={stateSelectedPet?.id === v.id && stateSelectedPet?.petPlace === v.petPlace} onSelected={stateBusy ? undefined : handleSelectedPet} />)}
                    </div>
                    <div className="fixed bottom">
                        <div className="exp">
                            If you've already submitted a claim, please contact our customer service team <a className="tel" href="tel:+18887194629">1.888.719.4629</a>
                        </div>
                        <button className="yellow" disabled={!stateSelectedPet || stateBusy} onClick={handleSubmit}>Continue</button>
                    </div>
                </div>
            </div>
        </TSPage>
    );
}

export { AnimalSelectorPage }
