import { AppProvider } from './app-context';
import { PageManager } from './pages/PageManager';
import './App.scss';

function App({ uiParams }) {
  return (
    <AppProvider uiParams={uiParams}>
      <PageManager />
    </AppProvider>
  );
}

export default App;
