import './ClaimTracker.scss';

const ClaimTracker = function ({ claim }) {
    let iconColors = {};

    switch (claim.status * 1) {
        case 0:
            iconColors.start = "-blue";
            break;
        case 1:
            iconColors.start = "-orange";
            iconColors.docs = "-orange";
            break;
        case 5:
            iconColors.start = "-blue";
            iconColors.docs = "-blue";
            iconColors.check = "-blue";
            break;
        case 20:
            iconColors.start = "-green";
            iconColors.docs = "-green";
            iconColors.check = "-green";
            iconColors.finish = "-green";
            break;
        case 25:
        case 26:
            iconColors.start = "-gray";
            iconColors.docs = "-gray";
            iconColors.check = "-gray";
            iconColors.finish = "-gray";
            break;
        default:
    }

    return (
        <div className={`claim-tracker state${claim.status * 1}`}>
            <div className="line">
                <div className="secondary state2"></div>
                <img className="icon start" src={`/img/tracker/icon-start${iconColors.start || ""}.svg`} alt="Start" />
                <img className="icon docs" src={`/img/tracker/icon-docs${iconColors.docs || ""}.svg`} alt="Documents" />
                <img className="icon check" src={`/img/tracker/icon-check${iconColors.check || ""}.svg`} alt="Checking" />
                <img className="icon finish" src={`/img/tracker/icon-finish${iconColors.finish || ""}.svg`} alt="finished" />
            </div>
        </div>
    );
};

export { ClaimTracker };