import { useState } from 'react';
import { useApp } from '../app-context';
import { TSPage } from '../components/commons';
import { DashService } from '../services/DashService';

import './LoginPage.scss';

const LoginPinPage = function ({ name, pageState }) {
    const [state, dispatcher] = useApp();

    const [stateError, setStateError] = useState();
    const [stateBusy, setStateBusy] = useState(false);

    const handleBack = (e) => {
        dispatcher({ action: "closePage", params: { id: "loginPin" } });
    };

    const hadnleSubmit = (e) => {
        e.preventDefault();
        if (stateBusy) return;

        setStateError(undefined);
        if (!document.getElementById("loginPin").value) {
            setStateError("Please enter the verification code received on a text message");
            return;
        }

        setStateBusy(true);
        DashService.login(state.loginKey, document.getElementById("loginPin").value)
            .then((data) => {
                if (!data?.token)
                    throw Error("We are sorry, the service is not available at this time. Please try again later.")

                try {
                    window.localStorage.setItem("dashTkn", data.token);
                } catch (ex) { console.error(ex); }

                dispatcher({ action: "loggedIn", params: { token: data.token } });

                return DashService.tokenLogin(data.token);
            })
            .then((data) => {
                if (!data.profile)
                    throw Error("We could not find your policy details :(");
                dispatcher({ action: "gotProfile", params: { profile: data.profile, policies: data.policies } });
            })
            .catch(ex => {
                setStateError(ex?.message || "We are sorry, the service is not available at this time. Please try again later.");
            })
            .finally(() => {
                setStateBusy(false);
            });
    };

    return (
        <TSPage name={name} pageState={pageState} className="fading">
            <img className="bg full" src="/img/login/bg.svg" alt="" />
            <img className="bg" src="/img/login/back-elements.svg" alt="" />
            <img className="bg front" src="/img/login/front-elements.svg" alt="" />

            <div className="content">
                <form onSubmit={hadnleSubmit}>
                    <button className="backBtn" type="button" onClick={handleBack}>
                        <img src="/img/close.svg" alt="back" />
                    </button>
                    <h1 className="header">Great, lets complete <br />the sign-in process</h1>

                    <div className="field">
                        <label htmlFor="loginTel">Verification code (sent via text)</label>
                        <input id="loginPin" type="text" inputMode="numeric" />
                    </div>
                    <div className="error-msg">
                        {stateError}
                    </div>
                    <button className="yellow submit">Sign in</button>
                </form>
            </div>
        </TSPage>
    );
}

export { LoginPinPage };