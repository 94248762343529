import { useState } from "react";
import "./InnerMenu.scss";

const Item = ({ type, id, text, icon, selected, onClick }) => {
    return (
        <div className={selected ? " selected" : ""} onClick={onClick}>
            <img src={icon ? icon : (selected ? `/img/menu-icon-${type}-selected.svg` : `/img/menu-icon-${type}.svg`)} alt="" />
            <div className="text">{text}</div>
        </div>
    )
};

const InnerMenu = ({ items }) => {
    const handleClick = (v, e) => {
        if (v.onClick)
            v.onClick(e);
    };

    return (
        <div className="innerMenu">
            {items?.map(v => <Item key={"item" + v.id} type={v.type} id={v.id} text={v.text} icon={v.icon} selected={v.selected} onClick={handleClick.bind(this, v)} />)}
        </div>
    );
}

export { InnerMenu }