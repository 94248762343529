import React from 'react'
import { LoginPage } from './pages/LoginPage';
import { LoginPinPage } from './pages/LoginPinPage';
import { MainPage } from './pages/MainPage';
import { PolicyPage } from './pages/PolicyPage';
import { MsgPage } from './pages/MsgPage';
import { MenuPage } from './pages/MenuPage';
import { DashService } from './services/DashService';
import { AnimalSelectorPage } from './pages/AnimalSelectorPage';
import { BotClaimPage } from './pages/BotClaimPage';

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

const pages_def = {
    login: { component: LoginPage },
    loginPin: { component: LoginPinPage },
    msg: { component: MsgPage, transTime: 330 },
    main: { component: MainPage },
    policy: { component: PolicyPage },
    menu: { component: MenuPage, transTime: 330 },
    animalSelector: { component: AnimalSelectorPage, transTime: 330 },
    botClaim: { component: BotClaimPage, transTime: 330 }
};

function actionHandler(state, { action, params }) {
    switch (action) {
        case 'loggedIn':
            return { ...state, _token: params.token };
        case 'gotProfile': {
            const pages = state._pages.filter(v => v.id !== "login" && v.id !== "loginPin");
            let pendingClaims = [];
            let openClaims = [];
            let closeClaims = [];
            let pets = [];
            (params.policies || []).forEach(v => {
                let pet = { ...v };
                pet.pendingClaims = [];
                pet.openClaims = [];
                pet.closeClaims = [];
                pet?.claims?.forEach(v => {
                    v = { ...v, pet };
                    if (v.status === -1) {
                        pet.pendingClaims.push(v);
                        pendingClaims.push(v);
                    } else if ((v.status | 0) < 20) {
                        pet.openClaims.push(v);
                        openClaims.push(v);
                    } else {
                        pet.closeClaims.push(v);
                        closeClaims.push(v);
                    }
                });
                delete pet.claims;
                pets.push(pet);
            });

            pages.push({ ...pages_def.main, id: "main", props: Object.assign({ key: "mainpage", name: "main" }, pages_def.main?.props || {}, params.props || {}) });
            return { ...state, profile: { info: params.profile.info, pets, openClaims, closeClaims, pendingClaims }, _pages: pages };
        }
        case 'refreshProfile': {
            let pendingClaims = [];
            let openClaims = [];
            let closeClaims = [];
            let pets = [];
            (params.policies || []).forEach(v => {
                let pet = { ...v };
                pet.pendingClaims = [];
                pet.openClaims = [];
                pet.closeClaims = [];
                pet?.claims?.forEach(v => {
                    v = { ...v, pet };
                    if (v.status === -1) {
                        pet.pendingClaims.push(v);
                        pendingClaims.push(v);
                    } else if ((v.status | 0) < 20) {
                        pet.openClaims.push(v);
                        openClaims.push(v);
                    } else {
                        pet.closeClaims.push(v);
                        closeClaims.push(v);
                    }
                });
                delete pet.claims;
                pets.push(pet);
            });
            return { ...state, profile: { info: params.profile.info, pets, openClaims, closeClaims, pendingClaims } };
        }
        case 'setLoginKey':
            return { ...state, loginKey: params.key };

        case 'showPage': {
            const pages = [...state._pages].filter(v => v.id !== params.id);
            if (pages_def[params.id]) {
                const props = Object.assign({ key: params.id + "page", name: params.id }, pages_def[params.id].props || {}, params.props || {});
                pages.push({ ...pages_def[params.id], id: params.id, distinct: params.distinct, props: props });
            }
            return { ...state, _pages: pages };
        }

        case 'closePage':
            return { ...state, _pages: state._pages.filter(v => v.id !== params.id) };

        case 'disconnect': {
            try {
                window.localStorage.removeItem("dashTkn");
            } catch (ex) { console.error(ex); }

            DashService.logout(state._token)
                .catch(ex => {
                    console.error("failed to logout", ex);
                });

            const pages = [];
            if (pages_def.login) {
                pages.push({ ...pages_def.login, id: "login", props: { key: "loginpage", name: "login" } });
            }
            return { _pages: pages, uiParams: state.uiParams, firstLogin: false };
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`)
        }
    }
}

function AppProvider({ uiParams, children }) {
    const [state, dispatch] = React.useReducer(actionHandler, { _pages: [], uiParams: uiParams, firstLogin: true })

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                {children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    )
}

function useAppState() {
    const context = React.useContext(AppStateContext);
    if (context === undefined)
        throw new Error('useAppState must be used within AppProvider');
    return context;
}

function useAppDispatch() {
    const context = React.useContext(AppDispatchContext);
    if (context === undefined)
        throw new Error('useAppDispatch must be used within AppProvider');
    return context;
}

function useApp() {
    return [useAppState(), useAppDispatch()]
}

export { AppProvider, useAppState, useAppDispatch, useApp }