import { useEffect, useRef } from 'react';
import { useApp } from '../app-context';
import { TSPage } from '../components/commons';

const MsgPage = function (props) {
    const popupRef = useRef();
    useEffect(() => {
        popupRef.current?.querySelector("button")?.focus();
    }, []);

    const [, dispatcher] = useApp();

    const handleClose = function (e) {
        e.preventDefault();
        dispatcher({ action: "closePage", params: { id: "msg" } });
    };

    return (
        <TSPage name={props.name} className="fading fast" pageState={props?.pageState} onClick={handleClose}>
            <div className="popupMsg" ref={popupRef}>
                {props.children}
            </div>
        </TSPage>
    );
}

export { MsgPage }
