import { useApp } from '../app-context';
import './BanneredMenu.scss';
import { InnerMenu } from './InnerMenu';
import { DashService } from '../services/DashService';

const BanneredMenu = function ({ policy }) {
    const [state, dispatcher] = useApp();
    const hasPolicy = !!policy;

    const handleHome = (e) => {
        dispatcher({ action: "closePage", params: { id: "policy" } });
        dispatcher({ action: "showPage", params: { id: "main" } });
    }

    const handleOpenMenu = (e) => {
        dispatcher({ action: "showPage", params: { id: "menu" } });
    };

    const handleOpenClaim = async (e) => {
        if (hasPolicy) {
            let data = await DashService.openClaim(state._token, policy?.id);
            console.log(data);
            dispatcher({ action: 'showPage', params: { id: 'botClaim', props: { botScript: data.claimScript, botToken: data.claimSession } } })
        } else {
            dispatcher({ action: "showPage", params: { id: "animalSelector" } });
        }
    };

    const handlePolicySelected = (policy, e) => {
        e.preventDefault();
        dispatcher({ action: "closePage", params: { id: "main" } });
        dispatcher({ action: "showPage", params: { id: "policy", props: { policy: policy }, distinct: policy.id } });
    };

    const items = [
        { type: "home", id: "home", text: "Home", onClick: handleHome, selected: state._pages?.filter(v => v.id === 'main')?.length > 0 }
    ];

    const getPetNames = (pets) => {
        pets = pets || [];
        let names = pets[0].petName;
        for (let i = 1; i < pets.length; i++) {
            names = names + ", " + pets[i].petName;
        }
        return ' (' + names + ')';
    }

    state.profile?.pets?.forEach(v => {
        let text = v.details.pets?.length > 1 ? v.details.pets?.length + ' pets policy' + getPetNames(v.details.pets) : v.details.name + "'s policy";
        let isSelected = state._pages?.filter(p => p?.props?.policy?.id === v.id)?.length > 0;
        items.push({ type: "policy", id: "policy" + v.id, text: text, selected: isSelected, onClick: handlePolicySelected.bind(this, v) });
    });

    const txtPerTimOfDay = () => {
        const time = new Date().getHours();
        if (time < 5)
            return 'Good night ';
        else if (time < 12)
            return 'Good morning ';
        else if (time < 17)
            return 'Good afternoon ';
        else if (time < 21)
            return 'Good evening ';
        else
            return 'Good night ';
    };

    return (
        <div className={"bannered-menu " + (!!policy ? "policy" : "")}>
            <div className="banner">
                <img src="/img/banner.svg" alt="banner" />
            </div>
            <img className={`logo ${hasPolicy ? " hasPolicy" : ""}`} src="/img/logo-white.svg" alt="chayuta logo" />
            <div className='timeofday'>{txtPerTimOfDay()}{state.profile?.pets[0]?.firstName && (" " + state.profile?.pets[0]?.firstName)}</div>
            <div className="headersButtons">
                <button className="more menu-item"><img src="/img/more-white.svg" alt="menu" onClick={handleOpenMenu} /></button>
                <button className="yellow file-claim-btn menu-item" onClick={handleOpenClaim}>Submit a new claim</button>
                <a href="tel:+18887194629" className="dial-link tel menu-item"><img src="/img/tel-new.svg" alt="tel" /><span>1.888.719.4629</span></a>
                {hasPolicy ?
                    <button className="btn right" onClick={handleHome}>
                        <img src="/img/right-arrow.svg" alt="back" />
                    </button>
                    :
                    undefined
                }
            </div>

            <div className="menu-container">
                <InnerMenu items={items} />
            </div>

            <div className="profile">
                <div className="inner">
                    <img className="avatar" src="/img/avatar-person-new.svg" alt="avatar" />
                    <div className="name">{(state.profile?.pets[0]?.firstName && (state.profile?.pets[0]?.firstName + "")) + " " + (state.profile?.pets[0]?.lastName || "")}</div>
                    <div className="more">
                        <button onClick={handleOpenMenu}>
                            <img src="/img/more-blue.svg" alt="menu" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { BanneredMenu }