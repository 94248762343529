import { useState } from 'react';
import { useApp } from '../app-context';
import { TSPage } from '../components/commons';
import { DashService } from '../services/DashService';
import { Utils } from '../Utils';

import './LoginPage.scss';

const LoginPage = function ({ name, pageState }) {
    const [, dispatcher] = useApp();

    const [stateError, setStateError] = useState();
    const [stateBusy, setStateBusy] = useState(false);

    const handleBack = (e) => {
        Utils.displaySimpleYNQuestion(dispatcher, {
            content: "Are you sure you want to leave the members portal?",
            onYes: (e) => {
                window.location.href = "https://animalia.pet";
            }
        })
    };

    const hadnleSubmit = (e) => {
        e.preventDefault();
        if (stateBusy) return;

        setStateError(undefined);
        if (!document.getElementById("loginTel").value || !document.getElementById("loginEmail").value) {
            setStateError("Please enter the phone number and email address used to set up your policy");
            return;
        }

        setStateBusy(true);
        DashService.requestLoginCode(document.getElementById("loginTel").value, document.getElementById("loginEmail").value)
            .then((data) => {
                dispatcher({ action: "setLoginKey", params: { key: data.key } });
                dispatcher({ action: "showPage", params: { id: "loginPin" } });
            })
            .catch(ex => {
                setStateError(ex?.message || "We are sorry, the service is not available at this time. Please try again later.");
            })
            .finally(() => {
                setStateBusy(false);
            });
    };

    return (
        <TSPage name={name} pageState={pageState} className="fading">
            <img className="bg full" src="/img/login/bg.svg" alt="" />
            <img className="bg" src="/img/login/back-elements.svg" alt="" />
            <img className="bg front" src="/img/login/front-elements.svg" alt="" />

            <div className="content">
                <form onSubmit={hadnleSubmit}>
                    <button type="button" className="backBtn" onClick={handleBack}>
                        <img src="/img/close.svg" alt="back" />
                    </button>
                    <h1 className="header">Log in to your <br /> Animalia's account</h1>

                    <div className="field">
                        <label htmlFor="loginTel">Phone number</label>
                        <input id="loginTel" type="tel" />
                    </div>
                    <div className="field">
                        <label htmlFor="loginEmail">Email address</label>
                        <input id="loginEmail" type="email" inputMode="email" />
                    </div>
                    <div className="error-msg">
                        {stateError}
                    </div>
                    <button className="yellow submit">Get your code via text 📱</button>
                </form>
            </div>
        </TSPage>
    );
}

export { LoginPage };