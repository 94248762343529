import './BotClaimPage.scss';

import { TSPage } from "../components/commons";
import { BOT_URL } from "../consts";
import { useEffect } from 'react';
import { useApp } from '../app-context';
import { DashService } from '../services/DashService';

const BotClaimPage = ({ botScript, botToken, name, pageState }) => {
    const [state, dispatcher] = useApp();

    useEffect(() => {
        let oldonmessage = window.onmessage;
        window.onmessage = function (e) {
            if (e.data === 'closeSalesBot') {
                dispatcher({ action: 'closePage', params: { id: name } });
                DashService.tokenLogin(state._token)
                    .then((data) => {
                        if (!data.profile)
                            throw Error("We couldn't find your policy details");
                        else
                            dispatcher({ action: "refreshProfile", params: { profile: data.profile, policies: data.policies } });
                    })
                    .catch(ex => {
                        console.error(ex);
                    });
            }

            if (oldonmessage)
                oldonmessage(e);
        };
        return () => {
            window.onmessage = oldonmessage;
        };
    }, []);
    return (
        <TSPage name={name} className="fading fast" pageState={pageState}>

            <iframe id="openClaimFrame" title="submit a claim" src={`${BOT_URL}/${encodeURIComponent(botScript)}.cht?tkn=${encodeURIComponent(botToken)}`}>

            </iframe>
        </TSPage>
    )
};

export { BotClaimPage }